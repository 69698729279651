<template>
  <tr>
    <td>
        <input
            class="form-control"
            type="text"
            v-model="amount"
            placeholder=""
        />
    </td>
    <td>
      <button class="ps-btn btn-sm p-3" @click.prevent="onAddVoucherAmount()" :disabled="amount <= 0">
        <i v-if="!isLoading" class="icon icon-cash-dollar p-0"></i>

        {{ isLoading ? "Adding..." : "Add Amount" }}

        <span
            v-if="isLoading"
            class="spinner-border text-light"
            role="status"
        ></span>
      </button>
    </td>
  </tr>
</template>

<script>
export default {
  name: "VoucherAmount",
  data() {
    return {
      amount: 0,
      isLoading: false,
    }
  },
  methods: {
    onAddVoucherAmount() {
      this.$emit('add-voucher-amount', {
        amount: this.amount,
      })
      this.amount = null;
    }
  }
}
</script>

<style scoped>

</style>