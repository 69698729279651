export default {
    data() {
        return {
            isLoading: false,
            errors: null,
            isError: false,
            message: ''
        }
    },

    methods: {
    handleSuccess(response, message, redirectUrl = null) {
        this.isLoading = false;
        this.$toasted.success(message);
        console.log(response.status)
        if (response.status === 200 || response.status === 201) {
            console.log(redirectUrl)
            if (redirectUrl) {
                setTimeout(function(){
                    this.$router.push({name: redirectUrl});
                }, 500);
            }
        }
    },

    handleErrors(error, message = null) {
        console.log('... handleErrors ...')
        if (error) {
            console.log(error)
            console.log(error.status)
            console.log(error.response)
            if (error.response && error.response.status === 422) {
                this.errors = error.response.data.errors
            } else if(error.backendErrors && error.backendErrors.length > 0) {
                this.message = error.backendErrors[0]
            }
        }
        if (message) {
            this.$toasted.error(message);
        } else if (this.message) {
            this.$toasted.error(message);
        }
        this.isError = true
        this.isLoading = false;
    }
    }


}