<template xmlns="http://www.w3.org/1999/html">
  <div class="ps-form__content">
    <div class="row mt-3 mb-3" v-if="message">
      <div class="col-12">
        <small class="text-danger" v-if="isError">{{ message }}</small>
        <small class="text-success" v-else>{{ message }}</small>
      </div>
    </div>
    <form enctype="multipart/form-data">
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label>Name<sup>*</sup> </label>
            <div class="form-group__content">
              <input
                  class="form-control"
                  type="text"
                  v-model="form.name"
                  placeholder=""
              />
              <small v-if="errors && errors.name" class="phone_error text-danger">
                {{ errors.name[0] }}
              </small>
            </div>
          </div>
          <div class="form-group">
            <label>Category<sup>*</sup> </label>
            <div class="form-group__content">
              <select class="form-control" v-model="form.category_id">
                <option v-for="category in categories" :value="category.id" :key="category.id">{{ category.name }}</option>
              </select>
              <small v-if="errors && errors.category" class="category_error text-danger">
                {{ errors.category[0] }}
              </small>
            </div>
          </div>
          <div class="form-group">
            <label>Description<sup>*</sup> </label>
            <div class="form-group__content">
              <textarea
                  class="form-control"
                  type="text"
                  v-model="form.description"
                  placeholder=""
              ></textarea>
              <small v-if="errors && errors.description" class="description_error text-danger">
                {{ errors.description[0] }}
              </small>
            </div>
          </div>
          <div class="form-group">
            <label>Status<sup>*</sup> </label>
            <div class="form-group__content">
              <select class="form-control" v-model="form.status">
                <option value="active">Active</option>
                <option value="out_of_stock">Out Of Stock</option>
                <option value="disabled">Disabled</option>
              </select>
              <small v-if="errors && errors.status" class="text-danger">
                {{ errors.status[0] }}
              </small>
            </div>
          </div>
          <div class="form-group">
            <label>Image </label>
            <div class="form-group__content">
              <input type="file" name="file" @change="handleFileUpload($event)" accept="image/*" />
              <small v-if="errors && errors.image" class="text-danger">
                {{ errors.image[0] }}
              </small>
            </div>
          </div>
        </div>
        <div class="col-6 table-responsive">
          <table class="table ps-table ps-table--vendor table-bordered" width="100%">
            <thead>
              <tr>
                <th>Amount</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <voucher-amount
                  @add-voucher-amount="addVoucherAmount"
              ></voucher-amount>
              <tr v-for="(amount, index) in amounts" :key="index">
                <td>{{ amount | toCurrency }}</td>
                <td>
                  <a class="ml-3 text-danger" href="#" @click="removeVoucherAmount(index)"><i class="icon-trash" /></a>
                  &nbsp;&nbsp;
                </td>
              </tr>
              <tr v-for="(voucherAmount, index) in voucherAmounts" :key="index">
                <td>{{ voucherAmount.amount | toCurrency }}</td>
                <td>
                  <a class="ml-3 text-danger" href="#" @click="deleteVoucherAmount(voucherAmount.id)"><i class="icon-trash" /></a>
                  &nbsp;&nbsp;
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="form-group">
        <button class="ps-btn" @click.prevent="onSubmit()">
          <i v-if="!isLoading" class="icon icon-floppy-disk ml-1"></i>

          {{ isLoading ? "Submitting..." : "Submit" }}

          <span
              v-if="isLoading"
              class="spinner-border text-light"
              role="status"
          ></span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import FormMixin from "@/mixins/form.js";
import VoucherAmount from "./VoucherAmount.vue";
import voucherAmount from "./VoucherAmount.vue";
import {mapState} from "vuex";
export default {
  name: "VoucherForm",
  computed: {
    ...mapState({
      auth: (state) => state.auth,
    }),
  },
  components: {VoucherAmount},
  props: {
    voucherId: Number|String,
  },
  mixins: [FormMixin],
  data() {
    return {
      errors: {},
      message: null,
      voucher: null,
      categories: null,
      amounts: [],
      voucherAmounts: [],
      form: {
        status: 'active',
        name: '',
        description: '',
        category_id: null
      },
      uploadFile: null,
      show: true,
      isLoading: false,
    };
  },

  async created() {
    axios.get("vouchers/categories").then((response) => {
      this.categories = response.data;
    });

    if (this.voucherId) {
      this.getVoucher();
    }
  },

  methods: {

    getVoucher() {
      axios.get(`vouchers/${this.voucherId}`).then((response) => {
        this.voucher = response.data;
        this.form.name = this.voucher.name;
        this.form.description = this.voucher.description;
        this.voucherAmounts = response.data.amounts;
        this.form.status = this.voucher.status;
      });
    },

    addVoucherAmount(payload) {
      if (!payload.amount) {
        this.$toasted.error('Amount not valid')
        return
      }
      if (isNaN(payload.amount)) {
        this.$toasted.error('Amount not valid')
        return
      }
      if (this.amounts.indexOf(payload.amount) >= 0) {
        this.$toasted.error('Amount already added')
        return
      }
      this.amounts.push(payload.amount)
    },

    async onSubmit() {
      if (this.voucherId) {
        await this.updateVoucher();
      } else {
        await this.createVoucher();
      }
    },

    async handleFileUpload(e) {
      // if (!e.target.files[0]) return;
      // try {
      //   let imageFile = e.target.files[0];
      //   let filename = imageFile.name;
      //   let formData = new FormData();
      //   formData.set('filename', filename);
      //   formData.set('directory', 'images/vouchers') // Folder inside s3 where voucher images will store
      //   let res = await axios.post('vouchers/upload-image', formData);
      //
      //   let inputs = res.data.inputs;
      //   let attributes = res.data.attributes;
      //
      //   formData = new FormData();
      //   Object.keys(inputs).forEach((key) => {
      //     formData.append(key, inputs[key]);
      //   });
      //   formData.append('file', imageFile);
      //
      //   let result = await fetch(attributes.action, {
      //     method: 'POST',
      //     body: formData,
      //     // mode: 'cors',
      //   })
      //   console.log(result);
      //   console.log('Posted image to s3');
      // } catch (err) {
      //   console.log('Failed to upload image to s3');
      //   console.log(err);
      // }
      this.uploadFile = e.target.files[0];
    },

    createVoucher() {
      this.isLoading = true;
      let formData = new FormData();
      formData.append("image", this.uploadFile);
      Object.keys(this.form).forEach((key) => {
        formData.append(key, this.form[key]);
      });
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        }
      }
      formData.append('amounts', this.amounts);
      console.log(formData);
      axios.post("vouchers/create", formData, config).then((response) => {
        let redirectUrl = 'admin_vouchers'
        if (this.auth.user.isAdmin) {
          redirectUrl = 'admin_vouchers'
        }
        this.handleSuccess(response, 'Voucher created successfully.', redirectUrl)
      }).catch((error) => {
        this.handleErrors(error, 'Voucher could not be created. Please enter all required fields.')
      });
    },

    updateVoucher() {
      this.isLoading = true;
      let formData = new FormData();
      formData.append("image", this.uploadFile);
      Object.keys(this.form).forEach((key) => {
        formData.append(key, this.form[key]);
      });
      // console.log(Object.keys(this.form));
      console.log(formData.values());
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        }
      }
      formData.append('amounts', this.amounts);
      console.log(formData);
      axios.post("vouchers/update/" + this.voucherId, formData, config).then((response) => {
         this.handleSuccess(response, 'Voucher successfully created.', 'merchant_vouchers')
      }).catch((error) => {
        this.handleErrors(error, 'Voucher could not be updated. Please enter all required fields.')
      });
    },

    deleteVoucherAmount(id) {
      if (confirm('Are you sure you want to delete this voucher amount')) {
        this.isDeleting = true;
        axios.delete('vouchers/amounts/' + id).then((response) => {
          this.getVoucher()
          this.isDeleting = false;
          this.$toasted.success('Voucher amount deleted successfully')
        }).catch((error) => {
          this.isDeleting = false;
          this.$toasted.error('Voucher amount could not be deleted')
        });
      }
    },
  },
}
</script>

<style scoped>

</style>